export default {
  prefix: 'vv',
  iconName: 'bubbles',
  icon: [
    445.68,
    516.72,
    [],
    null,
    'M224.81 336.49v18c27.98 0 50.75 22.76 50.75 50.75h18c0-37.91-30.84-68.75-68.75-68.75ZM281.24 241.59c21.59 0 39.16-17.57 39.16-39.16s-17.57-39.15-39.16-39.15-39.15 17.57-39.15 39.15 17.57 39.16 39.15 39.16Zm0-60.31c11.67 0 21.16 9.49 21.16 21.15s-9.49 21.16-21.16 21.16-21.15-9.49-21.15-21.16 9.49-21.15 21.15-21.15Z M381.98 293.77c-31.27 0-57.33 22.65-62.67 52.41-19.73-31.45-54.71-52.41-94.5-52.41-19.84 0-38.49 5.22-54.64 14.34 2.15-7.54 3.3-15.5 3.3-23.72 0-47.82-38.91-86.73-86.73-86.73S0 236.56 0 284.38s38.91 86.73 86.73 86.73c12.27 0 23.95-2.57 34.54-7.18-5.12 12.78-7.94 26.72-7.94 41.31 0 61.46 50.01 111.47 111.47 111.47s111.47-50.01 111.47-111.47c0-1.18-.02-2.36-.06-3.53 11.59 11.98 27.81 19.45 45.76 19.45 35.13 0 63.7-28.58 63.7-63.7s-28.58-63.7-63.7-63.7ZM86.73 353.12c-37.9 0-68.73-30.83-68.73-68.73s30.83-68.73 68.73-68.73 68.73 30.83 68.73 68.73-30.83 68.73-68.73 68.73Zm138.08 145.6c-51.54 0-93.47-41.93-93.47-93.47s41.93-93.47 93.47-93.47 93.47 41.93 93.47 93.47-41.93 93.47-93.47 93.47Zm157.17-95.55c-25.2 0-45.7-20.5-45.7-45.7s20.5-45.7 45.7-45.7 45.7 20.5 45.7 45.7-20.5 45.7-45.7 45.7ZM177.04 127.4c35.12 0 63.7-28.58 63.7-63.7S212.16 0 177.04 0s-63.7 28.58-63.7 63.7 28.58 63.7 63.7 63.7Zm0-109.4c25.2 0 45.7 20.5 45.7 45.7s-20.5 45.7-45.7 45.7-45.7-20.5-45.7-45.7 20.5-45.7 45.7-45.7Z',
  ],
}
