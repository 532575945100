export default {
  prefix: 'vv',
  iconName: 'bee',
  icon: [
    667.98,
    544.84,
    [],
    null,
    'M541.84 129.77c-26.56 0-64.6 17.9-101.25 39.83-9.35-28.67-29.87-51.93-55.98-65.21.8-47.77 39.91-86.39 87.87-86.39V0c-55.47 0-101.11 42.88-105.53 97.22-10.47-3.24-21.54-4.99-32.92-4.99s-22.49 1.75-32.98 5C296.62 42.88 250.98 0 195.51 0v18c47.96 0 87.07 38.62 87.87 86.4-26.12 13.28-46.63 36.54-55.98 65.2-36.65-21.93-74.69-39.83-101.25-39.83C56.58 129.77 0 186.36 0 255.91s56.58 126.14 126.14 126.14c25.24 0 60.83-16.15 95.74-36.56v87.27c0 61.8 50.3 112.08 112.14 112.08S446.1 494.56 446.1 432.76v-87.27c34.91 20.42 70.51 36.56 95.74 36.56 69.56 0 126.14-56.58 126.14-126.14S611.4 129.76 541.84 129.76Zm-207.82-19.54c42.43 0 79.62 28.73 90.72 69.14-40.56 25.72-76.94 54-90.75 64.99-13.8-10.99-50.19-39.27-90.74-64.99 11.09-40.4 48.31-69.14 90.77-69.14ZM18 255.91c0-59.63 48.51-108.15 108.14-108.15 51.92 0 161.7 83.22 193.41 108.15-31.72 24.93-141.49 108.14-193.41 108.14C66.51 364.05 18 315.54 18 255.91Zm410.1 157.94H239.88V364.8h188.21v49.05Zm-94.08 112.99c-51.91 0-94.14-42.21-94.14-94.08v-.9h188.21v.9c0 51.88-42.21 94.08-94.08 94.08ZM428.1 346.8H239.88v-12.23c41.89-26.27 79.92-55.81 94.11-67.1 14.18 11.3 52.21 40.84 94.11 67.1v12.23Zm113.74 17.26c-51.94 0-161.78-83.28-193.45-108.17 45.06-35.67 146.35-108.12 193.45-108.12 59.63 0 108.14 48.52 108.14 108.15s-48.51 108.14-108.14 108.14Z',
  ],
}

