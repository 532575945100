export default {
  prefix: 'vv',
  iconName: 'cart',
  icon: [
    573.49,
    504.48,
    [],
    null,
    'M256.09 504.48c-33.93 0-61.54-27.6-61.54-61.53s27.6-61.53 61.54-61.53 61.53 27.6 61.53 61.53-27.6 61.53-61.53 61.53Zm0-105.06c-24.01 0-43.54 19.53-43.54 43.53s19.53 43.53 43.54 43.53 43.53-19.53 43.53-43.53-19.53-43.53-43.53-43.53ZM439.95 504.48c-33.93 0-61.54-27.6-61.54-61.53s27.6-61.53 61.54-61.53 61.53 27.6 61.53 61.53-27.6 61.53-61.53 61.53Zm0-105.06c-24.01 0-43.54 19.53-43.54 43.53s19.53 43.53 43.54 43.53 43.53-19.53 43.53-43.53-19.53-43.53-43.53-43.53ZM518.75 326.63H183.86l-8.8-7.13L110.91 18H0V0h118.2l8.8 7.13 20.84 97.94h416.88l8.78 10.98-45.96 203.56-8.78 7.02Zm-327.6-18h320.4l41.9-185.56H151.66l39.48 185.56Z',
  ],
}
