export default {
  prefix: 'vv',
  iconName: 'droplet',
  icon: [
    235.2,
    369.01,
    [],
    null,
    'M125.38 0h-15.56C105.33 7.72 0 189.71 0 251.4 0 316.25 52.75 369 117.6 369s117.6-52.76 117.6-117.6S129.86 7.72 125.38 0Zm-7.78 351.01c-54.92 0-99.6-44.68-99.6-99.6 0-47.75 74.91-184.8 99.6-228.69 24.69 43.88 99.6 180.94 99.6 228.69 0 54.92-44.68 99.6-99.6 99.6Z M66.36 246.75c0-3.11.59-7.2 1.75-12.14l-17.52-4.13c-1.5 6.39-2.23 11.71-2.23 16.27 0 27.98 14.1 53.7 37.73 68.78l9.68-15.18c-18.42-11.75-29.41-31.79-29.41-53.6Z',
  ],
}
