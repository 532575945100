export default {
  prefix: 'vv',
  iconName: 'light-bulb',
  icon: [
    373.46,
    417.02,
    [],
    null,
    'M188.33 72.33c-59.72 0-108.31 48.59-108.31 108.31 0 35.71 17.3 68.55 46.54 88.85v57.6l9 9H241.1l9-9v-57.6c29.24-20.3 46.54-53.14 46.54-88.85 0-59.72-48.59-108.31-108.31-108.31Zm0 138.23c-6.65 0-12.06-5.41-12.06-12.06s5.41-12.07 12.06-12.07 12.07 5.41 12.07 12.07-5.41 12.06-12.07 12.06Zm47.98 46.48-4.21 7.62v53.44h-34.77v-91.07c12.17-3.85 21.07-15.11 21.07-28.54 0-16.58-13.49-30.07-30.07-30.07s-30.06 13.49-30.06 30.07c0 13.42 8.9 24.68 21.06 28.54v91.07h-34.77v-53.44l-4.21-7.62c-26.51-16.68-42.33-45.24-42.33-76.4 0-49.8 40.51-90.31 90.31-90.31s90.31 40.51 90.31 90.31c0 31.16-15.83 59.72-42.33 76.4ZM130.46 358.72H246.2v18H130.46zM155.087 399.038l66.172-1.074.292 17.997-66.171 1.074zM-.006 224.894l45.595-11.283 4.324 17.472L4.32 242.367zM.943 143.705l4.991-17.294 45.128 13.025-4.991 17.294zM47.342 56.585l12.972-12.479 32.563 33.85-12.972 12.479zM131.087 4.313 148.56-.011l11.283 45.594-17.473 4.325zM216.726 46.055 229.751.927l17.295 4.992-13.026 45.128zM283.045 79.907l33.85-32.563 12.478 12.972-33.85 32.563zM323.546 142.345l45.595-11.284 4.324 17.473-45.595 11.284zM322.396 234.007l4.992-17.294 45.128 13.026-4.992 17.294z',
  ],
}
