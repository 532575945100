export default {
  prefix: 'vv',
  iconName: 'magnifying-glass',
  icon: [
    488.97,
    488.98,
    [],
    null,
    'm99.64 66.86 8.73 15.74c25.61-14.2 57.96-9.64 78.7 11.09l12.73-12.73c-26.38-26.37-67.55-32.19-100.15-14.1Z M355.55 286.82h-12.73l-6.04 6.04-77.31-78.01c33.91-54.2 27.32-126.7-19.78-173.79-54.75-54.76-143.85-54.76-198.62 0-54.76 54.76-54.76 143.86 0 198.62 27.38 27.38 63.35 41.07 99.31 41.07 25.87 0 51.74-7.08 74.42-21.25l77.32 78.02-5.3 5.3v12.73l133.44 133.43h12.73l56-56v-12.73L355.56 286.82ZM53.79 226.96c-47.73-47.74-47.73-125.42 0-173.16C77.67 29.93 109.01 18 140.37 18s62.71 11.94 86.58 35.8c47.74 47.74 47.74 125.42 0 173.16-47.74 47.74-125.42 47.74-173.16 0Zm175.79 21.89c3.48-2.86 6.85-5.92 10.1-9.17 3.23-3.23 6.26-6.58 9.11-10.03l75.26 75.93-19.2 19.2-75.27-75.94Zm197.03 221.04L305.9 349.19l43.28-43.28 120.7 120.7-43.27 43.28Z',
  ],
}
