export default {
  prefix: 'vv',
  iconName: 'star',
  icon: [
    418.72,
    418.72,
    [],
    null,
    'M200.39 418.72C185.83 242.25 176.47 232.89 0 218.33v-17.94C176.47 185.83 185.83 176.47 200.39 0h17.94c14.55 176.47 23.92 185.83 200.39 200.39v17.94c-176.47 14.55-185.84 23.92-200.39 200.39h-17.94ZM83.87 209.36c85.65 13.49 111.99 39.84 125.49 125.49 13.49-85.65 39.84-112 125.49-125.49-85.65-13.5-112-39.84-125.49-125.49-13.5 85.65-39.84 111.99-125.49 125.49Z',
  ],
}
