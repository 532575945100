export default {
  prefix: 'vv',
  iconName: 'ticket',
  icon: [
    234.66,
    419.52,
    [],
    null,
    'M225.66 0h-67.68l-9 9c0 17.44-14.19 31.62-31.62 31.62S85.74 26.43 85.74 9l-9-9H9L0 9v401.52l9 9h67.8l8.94-10.27c0-17.43 14.19-31.62 31.62-31.62s31.62 14.19 31.66 31.52l8.89 10.36h67.74l9-9V9l-8.99-9Zm-9 401.52h-50.28c-3.73-23.7-24.29-41.88-49.02-41.88s-45.29 18.18-49.01 41.88H18V18h50.56c4.24 23.08 24.51 40.62 48.8 40.62s44.56-17.54 48.8-40.62h50.5v383.52Z M72.18 199.48h18.06v18H72.18zM36.06 199.48h18.06v18H36.06zM108.3 199.48h18.06v18H108.3zM144.42 199.48h18.06v18h-18.06zM180.54 199.48h18.06v18h-18.06z',
  ],
}
