export default {
  prefix: 'vv',
  iconName: 'clock',
  icon: [
    345.49,
    345.5,
    [],
    null,
    'M172.75 345.5C77.5 345.5 0 268 0 172.75S77.5 0 172.75 0 345.5 77.5 345.5 172.75 268 345.5 172.75 345.5Zm0-327.5C87.42 18 18 87.42 18 172.75S87.42 327.5 172.75 327.5 327.5 258.08 327.5 172.75 258.08 18 172.75 18Z m233.72 246.45-67.34-67.34-2.63-6.36V77.82h18v91.2l64.7 64.7-12.73 12.73z',
  ],
}
