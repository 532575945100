export default {
  prefix: 'vv',
  iconName: 'globe',
  icon: [
    384.07,
    498.36,
    [],
    null,
    'M318.26 318.25C406 230.51 406 87.74 318.26 0h-12.73l-48.29 48.29C199.18-3.19 110.03-1.14 54.45 54.45c-55.58 55.59-57.62 144.73-6.16 202.79L0 305.53v12.73c43.85 43.86 101.46 65.79 159.06 65.81v96.31H74.44v18h182.87v-18h-80.24v-97.03c51.51-4.08 101.88-25.76 141.19-65.08ZM211.92 198.18l-39.56-39.56 80.39-80.39c5.81 39.36-9.31 84.08-40.83 119.95ZM74.79 256.2l84.84-84.84 39.77 39.77c-36.3 34.1-83.23 51.1-124.61 45.08Zm84.84-110.3-42.64-42.64c36.48-31.8 82.75-46.13 123.83-38.55l-81.19 81.19Zm-12.73 12.73-85.83 85.83c-7.89-43.26 8.37-91.86 42.96-128.72l42.88 42.88Zm65.23 65.23 32.89 32.89c-40.58 35.74-98.28 41.86-144.72 18.35 38.99-2.42 79.44-20.64 111.83-51.24Zm45.55 20.1-33.01-33.01c30.59-34.43 47.42-76.74 47.27-116.5 26.91 46.96 22.16 107.26-14.25 149.51ZM221.04 44.77c-40.36-.61-82.67 15.59-116.81 45.72L74.3 60.56c24.3-20.94 54.56-31.42 84.83-31.42 21.33 0 42.67 5.21 61.91 15.62ZM61.51 73.23l29.78 29.78c-30.08 31.87-47.73 71.98-49.93 111.26C19.98 168.49 26.7 112.74 61.51 73.23ZM19.23 311.75l41.79-41.79c27.92 24.74 63.01 37.12 98.11 37.12 37.91 0 75.82-14.43 104.68-43.29 55.58-55.58 57.63-144.72 6.16-202.79l41.79-41.78c74.44 81.12 72.36 207.7-6.23 286.29-78.6 78.59-205.17 80.66-286.3 6.23Z',
  ],
}
