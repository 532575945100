export default {
  prefix: 'vv',
  iconName: 'loop',
  icon: [
    528.63,
    229.35,
    [],
    null,
    'M413.96 0c-52.82 0-126.88 76.28-149.64 101.17C241.55 76.28 167.49 0 114.67 0 51.45 0 0 51.45 0 114.67s51.45 114.67 114.67 114.67c52.82 0 126.88-76.28 149.64-101.17 22.76 24.89 96.82 101.17 149.64 101.17 63.23 0 114.67-51.45 114.67-114.67S477.19 0 413.96 0ZM114.67 211.35c-53.31 0-96.67-43.37-96.67-96.67S61.37 18 114.67 18c41.67 0 109.03 65.03 137.63 96.67-28.59 31.65-95.96 96.67-137.63 96.67Zm299.29 0c-41.67 0-109.04-65.03-137.63-96.67 28.59-31.65 95.95-96.67 137.63-96.67 53.31 0 96.67 43.37 96.67 96.67s-43.37 96.67-96.67 96.67Z',
  ],
}
