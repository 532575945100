export default {
  prefix: 'vv',
  iconName: 'ant',
  icon: [
    537.35,
    635.68,
    [],
    null,
    'M496.05 266.44 418 211.6h-10.35l-69.58 48.9c-1.47-8.97-3.81-17.49-6.97-25.34l77-54.11 3.83-7.37v-60.71l33-23.19-10.35-14.73-36.83 25.88-3.83 7.37v60.71l-71.06 49.93c-6.62-10.62-14.9-19.22-24.28-25.18 15.13-9.83 25.18-26.85 25.18-46.2 0-13.95-5.25-26.67-13.83-36.38l42.76-26.68 4.21-7.02 5.23-76.25L344.18 0l-4.9 71.66-44.18 27.57c-7.85-4.3-16.84-6.76-26.41-6.76s-18.56 2.46-26.41 6.76L198.1 71.66 193.18 0l-17.95 1.23 5.21 76.25 4.21 7.02 42.76 26.68c-8.58 9.72-13.83 22.43-13.83 36.38 0 19.35 10.05 36.37 25.18 46.2-9.37 5.95-17.65 14.55-24.28 25.18l-71.06-49.93V108.3l-3.83-7.37-36.83-25.88-10.35 14.73 33 23.19v60.71l3.83 7.37 77 54.11c-3.16 7.85-5.5 16.37-6.97 25.34l-69.58-48.9h-10.35l-78.05 54.84H0v18h44.15l5.17-1.63 75.2-52.85 73.02 51.32c0 .11-.01.22-.01.33 0 4.09.25 8.09.61 12.04l-89.59 15.64-7.32 7.32-16.41 93.96-29.2 29.21 12.73 12.73 31.21-31.22 2.5-4.82 15.81-90.54L201 311.42c5.59 23.49 17.57 43.06 33.15 54.89-40.41 10.7-62.27 41.86-62.27 90.5 0 33.38 10.42 77.58 26.54 112.59 19.68 42.74 44.62 66.29 70.25 66.29s50.57-23.54 70.25-66.29c16.12-35.02 26.54-79.21 26.54-112.59 0-48.63-21.87-79.8-62.27-90.5 15.58-11.84 27.57-31.41 33.15-54.89l83.13 14.51 15.81 90.54 2.5 4.82 31.21 31.22 12.73-12.73-29.2-29.21-16.41-93.96-7.32-7.32-89.59-15.64c.36-3.95.61-7.95.61-12.04 0-.11-.01-.22-.01-.33l73.02-51.32 75.2 52.85 5.17 1.63h44.14v-18h-41.3ZM231.58 147.56c0-20.45 16.64-37.09 37.1-37.09s37.1 16.64 37.1 37.09-16.64 37.1-37.1 37.1-37.1-16.64-37.1-37.1ZM347.46 456.8c0 60.52-38.16 160.88-78.79 160.88s-78.79-100.37-78.79-160.88c0-50.89 26.51-76.7 78.79-76.7s78.79 25.8 78.79 76.7Zm-78.79-96.26c-29.3 0-53.14-35.41-53.14-78.94s23.84-78.94 53.14-78.94 53.14 35.41 53.14 78.94-23.84 78.94-53.14 78.94Z',
  ],
}
