export default {
  prefix: 'vv',
  iconName: 'eye',
  icon: [
    352.03,
    232.28,
    [],
    null,
    'M176.02 188.97c-40.16 0-72.83-32.67-72.83-72.83s32.67-72.83 72.83-72.83 72.83 32.67 72.83 72.83-32.67 72.83-72.83 72.83Zm0-127.66c-30.23 0-54.83 24.6-54.83 54.83s24.6 54.83 54.83 54.83 54.83-24.6 54.83-54.83-24.6-54.83-54.83-54.83Z M176.02 232.28C79.31 232.28 3.19 125.86 0 121.33v-10.37C3.19 106.43 79.31 0 176.02 0s172.82 106.43 176.02 110.96v10.37c-3.19 4.53-79.31 110.95-176.02 110.95ZM18.62 116.14c16.3 20.95 81.54 98.14 157.39 98.14s141.09-77.19 157.39-98.14C317.09 95.19 251.86 18 176.01 18S34.9 95.18 18.62 116.14Z',
  ],
}
