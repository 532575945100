export default {
  prefix: 'vv',
  iconName: 'it',
  icon: [
    663.55,
    460.64,
    [],
    null,
    'm280.5 186.23-76.33 75.58v12.8l76.33 75.57 12.67-12.8-69.88-69.17 69.88-69.19-12.67-12.79zM381.18 199.02l69.87 69.19-69.87 69.17 12.67 12.8 76.33-75.57v-12.8l-76.33-75.58-12.67 12.79zM303.805 341.656l49.099-152.375 17.132 5.52-49.098 152.376z M654.55 0H9L0 9v442.64l9 9h645.55l9-9V9l-9-9Zm-9 18v69.15H18V18h627.55ZM18 442.64V105.15h627.55v337.49H18Z',
  ],
}
