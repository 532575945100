export default {
  prefix: 'vv',
  iconName: 'magnet',
  icon: [
    308.85,
    553.01,
    [],
    null,
    'M263.61 45.25C234.44 16.07 195.66 0 154.4 0 69.26 0 0 69.29 0 154.45V396l9 9h105.5l9-9V160.78c0-17.04 13.86-30.9 30.9-30.9 8.27 0 16.04 3.22 21.88 9.06s9.07 13.61 9.07 21.84V396l9 9h105.5l9-9V154.45c0-41.24-16.07-80.03-45.25-109.2ZM18 387v-53.94h87.5V387H18Zm171.02-260.79c-9.25-9.24-21.54-14.33-34.62-14.33-26.96 0-48.9 21.94-48.9 48.9v154.28H18V154.45C18 79.21 79.19 18 154.4 18c36.45 0 70.71 14.2 96.48 39.97 25.78 25.78 39.97 60.04 39.97 96.47v160.61h-87.5V160.78c0-13.04-5.09-25.31-14.34-34.57ZM203.36 387v-53.94h87.5V387h-87.5ZM213.66 462.59h18v90.42h-18zM262.55 435.24h18v90.42h-18zM69.04 462.59h18v90.42h-18zM20.14 435.24h18v90.42h-18z',
  ],
}
