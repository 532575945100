export default {
  prefix: 'vv',
  iconName: 'arrow-right',
  icon: [
    477.02,
    175.89,
    [],
    null,
    'm390.2 175.89-12.31-13.13L446.98 98 0 95.78l.09-18 449.15 2.24-71.35-66.88L390.2 0l86.82 81.38v13.14l-86.82 81.37z',
  ],
}
