export default {
  prefix: 'vv',
  iconName: 'electricity',
  icon: [
    572.36,
    256.32,
    [],
    null,
    'M572.36 83.17v-18h-84.59V9l-9-9H327.25c-54.62 0-101.36 34.35-119.77 82.57H9l-9 9v73.18l9 9h198.47c18.42 48.22 65.16 82.57 119.77 82.57h151.52l9-9v-56.16h84.59v-18h-84.59V83.17h84.59ZM18 155.75v-55.18h184.08c-1.96 8.89-3 18.12-3 27.59s1.04 18.7 3 27.59H18Zm451.77 82.57H327.25c-60.74 0-110.16-49.42-110.16-110.16S266.51 18 327.25 18h142.52v220.32Z',
  ],
}
