export default {
  prefix: 'vv',
  iconName: 'gift',
  icon: [
    400.01,
    369.43,
    [],
    null,
    'M391.01 109.33H251.22c22.07-8.75 44.96-19.12 53.05-27.21 18.76-18.76 18.76-49.29 0-68.06-18.77-18.76-49.29-18.75-68.05 0-11.15 11.15-26.62 50.37-36.21 76.86-9.59-26.49-25.06-65.71-36.21-76.86-18.76-18.76-49.29-18.75-68.06 0-18.76 18.76-18.76 49.29 0 68.06 8.1 8.09 30.98 18.46 53.06 27.21H9l-9 9v242.1l9 9h382.01l9-9v-242.1l-9-9Zm-9 121.05H209V127.33h173v103.05ZM248.94 26.79c5.87-5.87 13.59-8.81 21.3-8.81s15.43 2.94 21.3 8.81c11.74 11.75 11.74 30.86 0 42.6-8.33 8.33-46.51 23.62-77.05 34.45 10.83-30.54 26.12-68.71 34.45-77.06Zm-140.48 0c11.74-11.74 30.86-11.75 42.6 0 8.33 8.34 23.62 46.51 34.45 77.06-30.54-10.83-68.71-26.12-77.06-34.45-11.74-11.75-11.74-30.86 0-42.6ZM191 127.33v103.05H18V127.33h173ZM18 248.38h173v103.05H18V248.38Zm191 103.05V248.38h173v103.05H209Z',
  ],
}
