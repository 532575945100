export default {
  prefix: 'vv',
  iconName: 'chemistry',
  icon: [
    388.43,
    441.52,
    [],
    null,
    'M380.78 382.18 278.06 248.1c-16.44-21.45-25.49-48.15-25.49-75.18V65.94h12.47l9-9V9l-9-9H123.88l-9 9v47.94l9 9h11.98v106.99c0 27.03-9.05 53.72-25.49 75.18L7.64 382.18c-8.6 11.23-10.05 26.09-3.8 38.77 6.26 12.68 18.94 20.56 33.08 20.56H351.5c14.14 0 26.82-7.88 33.08-20.57 6.26-12.68 4.8-27.54-3.8-38.77ZM132.88 18h123.16v29.94H132.88V18Zm-8.23 241.05c18.83-24.57 29.2-55.16 29.2-86.12V65.94h80.71V97.3h-35.42v18h35.42v52.84h-34.19v18h34.82c1.75 18.45 7.14 36.47 15.75 52.85H198.4v18h63.8c.51.69 1.03 1.38 1.55 2.07l36.52 47.67H88.13l36.52-47.67Zm243.79 153.94c-3.25 6.59-9.59 10.53-16.94 10.53H36.93c-7.35 0-13.69-3.94-16.94-10.53-3.26-6.6-2.53-14.02 1.94-19.86l52.41-68.41h239.75l52.41 68.41c4.47 5.84 5.2 13.26 1.95 19.86Z',
  ],
}
