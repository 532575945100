export default {
  prefix: 'vv',
  iconName: 'antenna',
  icon: [
    543.19,
    543.19,
    [],
    null,
    'M370.47 190.28c4.65 2.18 9.84 3.4 15.31 3.4 20 0 36.26-16.27 36.26-36.27s-16.27-36.26-36.26-36.26-36.27 16.27-36.27 36.26c0 7.99 2.6 15.38 7 21.38l-51.9 51.9C199.46 127.62 76.75 88.38 23.64 141.49c-53.78 53.78-12.87 178.94 93.12 284.94 74.43 74.43 158.3 116.76 220.28 116.76 26.29 0 48.64-7.62 64.66-23.64 52.28-52.28 15.07-172.04-84.45-276.06l53.22-53.22Zm15.31-51.13c10.07 0 18.26 8.19 18.26 18.26s-8.19 18.27-18.26 18.27-18.27-8.2-18.27-18.27 8.2-18.26 18.27-18.26Zm3.19 367.68c-45.08 45.09-163.91 2.44-259.49-93.12-95.56-95.58-138.21-214.4-93.12-259.49 12.25-12.25 29.93-18.02 51.11-18.02 55.73 0 135.66 39.98 204.41 107.22L206.3 329l12.73 12.73 85.49-85.49c89.42 93.92 128.15 206.9 84.45 250.6ZM385.78 63.19v18c42.03 0 76.22 34.19 76.22 76.22h18c0-51.96-42.26-94.22-94.22-94.22Z M378.3 0v18c81 0 146.89 65.89 146.89 146.89h18C543.19 73.97 469.22 0 378.3 0Z',
  ],
}
