export default {
  prefix: 'vv',
  iconName: 'location',
  icon: [
    227.94,
    358.39,
    [],
    null,
    'M113.97 0C51.13 0 0 51.13 0 113.97s101.84 236.9 106.18 244.42h15.59c4.34-7.52 106.18-184.72 106.18-244.42C227.94 51.13 176.81 0 113.97 0Zm0 335.59C89.83 292.36 18 159.89 18 113.97 18 61.05 61.05 18 113.97 18s95.97 43.05 95.97 95.97c0 45.92-71.83 178.39-95.97 221.62Z M113.97 63.99c-25.29 0-45.87 20.58-45.87 45.87s20.58 45.87 45.87 45.87 45.87-20.58 45.87-45.87-20.58-45.87-45.87-45.87Zm0 73.74c-15.37 0-27.87-12.5-27.87-27.87s12.5-27.87 27.87-27.87 27.87 12.5 27.87 27.87-12.5 27.87-27.87 27.87Z',
  ],
}
