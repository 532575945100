export default {
  prefix: 'vv',
  iconName: 'mountain',
  icon: [
    631.81,
    408.37,
    [],
    null,
    'M537.66 100.18v-18H369.2L321.74 0h-15.59L258.7 82.18H85.97v18h162.35l-26.85 46.51H0v18h211.07L78.18 394.87l7.79 13.5h455.96l7.79-13.5-170.13-294.69h158.07ZM313.95 22.5l34.46 59.68h-68.92l34.46-59.68ZM101.56 390.37l96.7-167.49 12.95 34.23 15.41 2.49 48.01-59.17 32.34 73.22h16.46l32.34-73.22 48.02 59.17 15.54-2.85 10.86-32.92 96.15 166.54H101.56Zm316.57-187.43-10.73 32.54-46.92-57.81-15.22 2.03-30.06 68.04-30.05-68.04-15.22-2.03-47.42 58.44-12.61-33.36 59.21-102.55h89.7l59.33 102.75Z M467.19 146.7h164.63v18H467.19z',
  ],
}
