export default {
  prefix: 'vv',
  iconName: 'robot',
  icon: [
    571.19,
    529.73,
    [],
    null,
    'M362.7 269a55.59 55.59 0 1 0 55.59 55.59A55.66 55.66 0 0 0 362.7 269Zm0 93.18a37.59 37.59 0 1 1 37.59-37.59 37.64 37.64 0 0 1-37.59 37.63Zm13.49-37.59a13.5 13.5 0 1 1-13.5-13.5 13.49 13.49 0 0 1 13.5 13.54Zm-112.11 0a55.59 55.59 0 1 0-55.59 55.59 55.66 55.66 0 0 0 55.59-55.55Zm-55.59 37.59a37.59 37.59 0 1 1 37.59-37.59 37.64 37.64 0 0 1-37.59 37.63ZM222 324.63a13.5 13.5 0 1 1-13.5-13.5 13.49 13.49 0 0 1 13.5 13.5ZM326.91 419l17.1 5.62a61.51 61.51 0 0 1-116.85 0l17.1-5.62a43.51 43.51 0 0 0 82.65 0ZM527 299.55h-27.7V283a99.69 99.69 0 0 0-99.57-99.57h-44l58.5-107.86a38.44 38.44 0 1 0-15.93-8.36l-63 116.23h-99.37l-63-116.23A38.24 38.24 0 1 0 157 75.57l58.5 107.86h-44A99.69 99.69 0 0 0 71.91 283v16.55H44.22A44.3 44.3 0 0 0 0 343.79v81.85a44.3 44.3 0 0 0 44.24 44.24h27.67v50.86l9 9h409.36l9-9v-50.86H527a44.3 44.3 0 0 0 44.24-44.24v-81.85A44.3 44.3 0 0 0 527 299.55ZM423.53 18a20.36 20.36 0 1 1-20.36 20.36A20.38 20.38 0 0 1 423.53 18ZM147.66 58.72A20.36 20.36 0 1 1 168 38.36a20.38 20.38 0 0 1-20.34 20.36ZM71.93 451.87H44.24A26.27 26.27 0 0 1 18 425.63v-81.85a26.27 26.27 0 0 1 26.24-26.24h27.69Zm18 59.86V283a81.66 81.66 0 0 1 81.57-81.57h228.2A81.66 81.66 0 0 1 481.26 283v228.73Zm463.27-86.1a26.27 26.27 0 0 1-26.2 26.24h-27.74V317.54H527a26.27 26.27 0 0 1 26.24 26.24Zm-38.72-72.2h18V416h-18Zm-475.75 0h18V416h-18Z',
  ],
}
