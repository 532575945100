export default {
  prefix: 'vv',
  iconName: 'cake',
  icon: [
    382.62,
    412.22,
    [],
    null,
    'M76.38 85.15c16.2 0 29.37-13.18 29.37-29.37 0-12.42-16.33-45.83-21.33-55.78H68.34c-5 9.94-21.33 43.35-21.33 55.78 0 16.2 13.18 29.37 29.37 29.37Zm0-60.21c6.3 13.92 11.37 26.96 11.37 30.83 0 6.27-5.1 11.37-11.37 11.37s-11.37-5.1-11.37-11.37c0-3.87 5.07-16.92 11.37-30.83ZM194.29 85.15c16.2 0 29.37-13.18 29.37-29.37 0-12.42-16.33-45.83-21.33-55.78h-16.08c-5 9.94-21.33 43.35-21.33 55.78 0 16.2 13.18 29.37 29.37 29.37Zm0-60.21c6.3 13.92 11.37 26.96 11.37 30.83 0 6.27-5.1 11.37-11.37 11.37s-11.37-5.1-11.37-11.37c0-3.87 5.07-16.92 11.37-30.83ZM312.19 85.15c16.2 0 29.37-13.18 29.37-29.37 0-12.42-16.33-45.83-21.33-55.78h-16.08c-5 9.94-21.33 43.35-21.33 55.78 0 16.2 13.18 29.37 29.37 29.37Zm0-60.21c6.3 13.92 11.37 26.96 11.37 30.83 0 6.27-5.1 11.37-11.37 11.37s-11.37-5.1-11.37-11.37c0-3.87 5.07-16.92 11.37-30.83ZM373.62 163.14h-34.59v-62.11l-9-9h-35.67l-9 9v62.11h-64.24v-62.11l-9-9h-35.67l-9 9v62.11h-64.24v-62.11l-9-9H58.54l-9 9v62.11H9l-9 9v231.08l9 9h364.62l9-9V172.14l-9-9Zm-70.27-53.11h17.67v53.11h-17.67v-53.11Zm-117.91 0h17.67v53.11h-17.67v-53.11Zm-117.91 0H85.2v53.11H67.53v-53.11Zm-9 71.11h306.09v73.11H18v-73.11h40.54Zm306.08 91.11v30.87H18v-30.87h346.62ZM18 394.22v-73.11h346.62v73.11H18Z',
  ],
}
