export default {
  prefix: 'vv',
  iconName: 'planet',
  icon: [
    417.36,
    292.94,
    [],
    null,
    'M417.36 146.47c0-24.81-26.83-46.38-75.56-60.99C318.62 35.09 267.69 0 208.68 0S98.75 35.09 75.57 85.48C26.83 100.09 0 121.66 0 146.47c0 22.62 26.01 46.08 75.72 61.34 23.26 50.2 74.09 85.13 132.96 85.13s109.7-34.93 132.96-85.13c49.71-15.26 75.72-38.72 75.72-61.34ZM208.68 18c70.84 0 128.47 57.63 128.47 128.47 0 16.29-3.17 31.82-8.72 46.17-31.83 8.53-72.8 14.09-119.75 14.09s-87.92-5.56-119.75-14.09c-5.55-14.35-8.72-29.88-8.72-46.17C80.21 75.63 137.84 18 208.68 18ZM18 146.47c0-13.68 18.65-28.21 49.65-39.45-3.52 12.56-5.44 25.78-5.44 39.45s1.93 26.96 5.47 39.56C36.24 174.7 18 160.21 18 146.47Zm190.68 128.47c-46.01 0-86.34-24.4-109.03-60.85 29.95 6.65 66.4 10.64 109.03 10.64s79.08-3.99 109.03-10.64c-22.69 36.46-63.02 60.85-109.03 60.85Zm141-88.91c3.54-12.59 5.47-25.85 5.47-39.55s-1.92-26.89-5.44-39.45c31 11.23 49.64 25.77 49.64 39.45s-18.24 28.22-49.67 39.55Z',
  ],
}
