export default {
  prefix: 'vv',
  iconName: 'molecule',
  icon: [
    530.1,
    426.12,
    [],
    null,
    'm357.27 219.35 21.25 36.81-21.25 36.81 15.59 9 23.85-41.31v-9l-23.85-41.31-15.59 9zM223.72 214.76l23.86-41.32v-9l-23.86-41.31-15.58 9 21.25 36.81-21.25 36.82 15.58 9z"/><path d="M530.1 265.16v-18h-86.22l-44.5-77.07 42.6-75.01-15.66-8.89-42.56 74.94h-88.82L250.35 83.9l42.6-75.01L277.29 0l-42.64 75.08h-88.26L103.78 0 88.12 8.88l42.46 74.77-44.63 77.3H0v18h85.76l44.77 77.55-42.42 74.7 15.66 8.88 42.55-74.93h88.47l44.49 77.06-42.59 75.02 15.66 8.88 42.54-74.92h90.79l42.54 74.92 15.66-8.88-43.53-76.66 43.54-75.42h86.22ZM145.92 93.08h88.96l44.43 76.97-44.52 77.12h-88.87l-44.48-77.04 44.48-77.04ZM383.81 333.2h-88.96l-44.43-76.96 44.52-77.12h88.87l44.48 77.04-44.48 77.04Z',
  ],
}
