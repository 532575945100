export default {
  prefix: 'vv',
  iconName: 'newtons-cradle',
  icon: [
    612.83,
    370.28,
    [],
    null,
    'M609.18 280.83c-8.92-21.68-31.95-33.37-54.13-28.87L461.9 18h24.8V0H0v18h41.79v256.78C19.51 279.01 2.6 298.6 2.6 322.09c0 26.57 21.62 48.19 48.19 48.19s48.19-21.62 48.19-48.19c0-23.49-16.91-43.08-39.19-47.31V18h91.65v256.78c-22.28 4.23-39.19 23.82-39.19 47.31 0 26.57 21.62 48.19 48.19 48.19s48.19-21.62 48.19-48.19c0-23.49-16.91-43.08-39.19-47.31V18h91.65v256.78c-22.28 4.23-39.19 23.82-39.19 47.31 0 26.57 21.62 48.19 48.19 48.19s48.19-21.62 48.19-48.19c0-23.49-16.91-43.08-39.19-47.31V18h91.65v256.78c-22.28 4.23-39.19 23.82-39.19 47.31 0 26.57 21.62 48.19 48.19 48.19s48.19-21.62 48.19-48.19c0-23.49-16.91-43.08-39.19-47.31V18h53.77l95.86 240.76a47.81 47.81 0 0 0-18.24 21.86c-4.96 11.88-4.99 24.98-.09 36.88 7.65 18.6 25.66 29.86 44.63 29.86 6.09 0 12.29-1.17 18.26-3.62 11.91-4.9 21.19-14.14 26.14-26.02 4.96-11.88 4.99-24.98.09-36.88Zm-528.2 41.26c0 16.65-13.54 30.19-30.19 30.19S20.6 338.73 20.6 322.09s13.54-30.19 30.19-30.19 30.19 13.54 30.19 30.19Zm109.65 0c0 16.65-13.54 30.19-30.19 30.19s-30.19-13.55-30.19-30.19 13.54-30.19 30.19-30.19 30.19 13.54 30.19 30.19Zm109.66 0c0 16.65-13.55 30.19-30.19 30.19s-30.19-13.55-30.19-30.19 13.54-30.19 30.19-30.19 30.19 13.54 30.19 30.19Zm109.65 0c0 16.65-13.55 30.19-30.19 30.19s-30.19-13.55-30.19-30.19 13.54-30.19 30.19-30.19 30.19 13.54 30.19 30.19Zm182.53-11.3c-3.11 7.44-8.92 13.23-16.38 16.29-15.39 6.35-33.07-1.04-39.4-16.44-3.06-7.45-3.05-15.66.06-23.1s8.92-13.23 16.38-16.29c3.74-1.54 7.63-2.27 11.44-2.27 11.88 0 23.17 7.05 27.96 18.7 3.06 7.46 3.05 15.67-.06 23.11Z',
  ],
}
