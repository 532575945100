export default {
  prefix: 'vv',
  iconName: 'cloud-snow',
  icon: [
    490.21,
    649.8,
    [],
    null,
    'M381.79 118.47c-11.01 0-21.74 1.62-32.05 4.83C343.71 54.3 285.63 0 215.1 0S87.12 53.1 80.58 122.11C33.49 134.54 0 177.54 0 226.89c0 59.76 48.64 108.38 108.43 108.38h273.36c59.78 0 108.42-48.62 108.42-108.38s-48.64-108.42-108.42-108.42Zm0 198.8H108.43c-49.86 0-90.43-40.55-90.43-90.38 0-43.11 30.63-80.4 72.83-88.68l7.25-8.43C100.87 67.1 152.27 18 215.1 18s117.15 52.56 117.15 117.16v.98l12.7 8.2c11.58-5.22 23.98-7.87 36.84-7.87 49.86 0 90.42 40.56 90.42 90.42s-40.56 90.38-90.42 90.38ZM183.97 488.68l-23.19-40.15h45.25v-18h-45.24l22.62-39.19-15.59-9-22.62 39.19-22.63-39.19-15.58 9 22.62 39.19H83.24v18h46.37l-23.18 40.15 15.59 9 23.18-40.15 23.18 40.15 15.59-9zM384.34 391.34l-15.59-9-22.62 39.19-22.63-39.19-15.58 9 22.62 39.19h-46.37v18h46.37l-23.18 40.15 15.59 9 23.18-40.15 23.18 40.15 15.59-9-23.19-40.15h45.25v-18h-45.24l22.62-39.19zM283.88 543.46l-15.59-9-22.63 39.19-22.62-39.19-15.59 9 22.63 39.19h-46.37v18h46.37l-23.19 40.15 15.59 9 23.18-40.15 23.19 40.15 15.58-9-23.18-40.15h45.25v-18h-45.25l22.63-39.19z',
  ],
}
