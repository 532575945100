export default {
  prefix: 'vv',
  iconName: 'leaf',
  icon: [
    276.97,
    616.81,
    [],
    null,
    'M276.97 301.41C276.97 228.34 151.63 9.29 146.29 0h-15.61C125.34 9.29 0 228.34 0 301.41S57.31 434.94 129.49 439.6v177.21h18V439.6c72.18-4.65 129.49-64.85 129.49-138.19ZM147.49 39.08c16.92 30.84 43.74 81.16 66.84 130.83l-66.84 66.84V39.08Zm-18 197.67-66.84-66.84c23.1-49.67 49.92-99.99 66.84-130.83v197.67Zm0 25.45v95.67L27.51 255.9c6.64-20.46 16.24-44.11 27.16-68.51l74.81 74.82Zm18 0 74.82-74.82c10.92 24.4 20.52 48.05 27.16 68.51L147.5 357.86v-95.67ZM18 301.41c0-7.07 1.37-15.8 3.81-25.76l107.67 107.67v38.24c-62.25-4.62-111.49-56.74-111.49-120.15Zm129.49 120.15v-38.24l107.67-107.67c2.44 9.95 3.81 18.68 3.81 25.76 0 63.41-49.24 115.53-111.49 120.15Z',
  ],
}
