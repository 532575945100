export default {
  prefix: 'vv',
  iconName: 'sun',
  icon: [
    476.71,
    476.71,
    [],
    null,
    'M476.71 247.35v-18h-91.04c-2.05-33.93-15.62-64.81-36.84-88.76l64.42-64.42-12.73-12.73-64.42 64.42c-23.95-21.22-54.83-34.78-88.76-36.84V0h-18v91.03c-33.93 2.05-64.8 15.62-88.76 36.83l-64.4-64.41-12.73 12.73 64.42 64.42c-21.22 23.95-34.79 54.83-36.84 88.76H0v18h91.03c2.05 33.93 15.62 64.81 36.84 88.76l-64.42 64.41 12.73 12.73 64.42-64.41c23.95 21.22 54.82 34.78 88.76 36.83v91.03h18v-91.03c33.93-2.05 64.81-15.62 88.76-36.84l64.42 64.42 12.73-12.73-64.42-64.42c21.22-23.95 34.78-54.83 36.84-88.76h91.04Zm-238.36 120.6c-71.46 0-129.6-58.14-129.6-129.6s58.14-129.6 129.6-129.6 129.6 58.14 129.6 129.6-58.14 129.6-129.6 129.6Z',
  ],
}
