export default {
  prefix: 'vv',
  iconName: 'muscle',
  icon: [
    499.17,
    497.67,
    [],
    null,
    'M497.71 309C479.79 125.07 303.74 21.12 268.63 2.14l-4.21-1.08L109.79 0l-6.08 2.31C65.36 36.86 52.53 88.54 73.21 125.2c13.34 23.65 36.98 34.04 49.89 38.12l5.78-.12 104-37.65c10.38 6.24 23.47 16.8 32.9 33.7 13.25 23.73 12.33 47.48 9.78 61.97-13.05-6.41-27.82-11.51-44.62-14.03-47.88-7.16-86.56 10.08-105.76 21.33-11.02-11.23-30.19-26.4-57.9-33.13-28.1-6.81-52.1-2.07-67.28 3.11l5.81 17.04c12.91-4.41 33.32-8.45 57.22-2.65 27.88 6.77 45.63 23.63 53.72 33.06l11.78 1.66c13.99-9.2 51.7-29.82 99.73-22.62 66.96 10.04 99.64 64.99 105.51 75.9l15.86-8.53c-4.46-8.29-22.8-39.23-57.6-61.86 3.7-16 7.39-47.93-10.54-80.03-10.7-19.17-25.25-31.45-37.28-39.04l8.59-31.56-17.37-4.73-9.17 33.66-100.57 36.41c-10.72-3.81-27.39-12.13-36.81-28.85-16.13-28.59-5.85-69.47 24.35-98.34l148.8 1.02c37.8 20.77 201.03 120.01 217.75 291.7 3.54 36.41.25 73.64-9.8 110.71-44.8 27.8-116.18 59.81-207.96 58.16-55.08-.9-110.7-14.38-160.84-38.99l-8.12.09-31.29 16.27 8.3 15.97 27.29-14.19c51.43 24.5 108.16 37.92 164.37 38.84 2.19.04 4.35.05 6.52.05 94.83 0 168.67-33.97 214.28-62.84l3.84-5.16c11.38-40.34 15.18-80.94 11.31-120.67Z M231.02 407.61c54.53-5.12 90.13-37.79 102.91-51.6l-13.22-12.22c-11.37 12.29-43.03 41.37-91.38 45.91-67.46 6.36-112.33-39.15-120.65-48.33l-13.34 12.08c8.72 9.63 53.35 54.85 121.2 54.85 4.71 0 9.54-.22 14.48-.69Z',
  ],
}
