export default {
  prefix: 'vv',
  iconName: 'abacus',
  icon: [
    574.43,
    416.37,
    [],
    null,
    'M556.43 0v53.75H410.81c-4.23-22.26-23.82-39.15-47.3-39.15s-43.07 16.89-47.3 39.15h-64.44c-4.23-22.26-23.82-39.15-47.3-39.15s-43.07 16.89-47.3 39.15H18V0H0v416.37h18v-72.98h89.4c4.86 21.45 24.06 37.52 46.96 37.52s42.1-16.07 46.95-37.52h192.3c4.86 21.45 24.06 37.52 46.96 37.52s42.1-16.07 46.95-37.52h68.9v72.98h18V0h-18ZM363.51 32.6c16.63 0 30.15 13.52 30.15 30.15s-13.52 30.16-30.15 30.16-30.16-13.53-30.16-30.16 13.53-30.15 30.16-30.15Zm-159.05 0c16.63 0 30.16 13.52 30.16 30.15s-13.53 30.16-30.16 30.16-30.15-13.53-30.15-30.16 13.52-30.15 30.15-30.15Zm-47.3 39.15c4.23 22.27 23.82 39.16 47.3 39.16s43.07-16.89 47.3-39.16h64.44c4.23 22.27 23.82 39.16 47.3 39.16s43.07-16.89 47.3-39.16h145.62v113.59h-68.26c-3.56-23.07-23.54-40.79-47.59-40.79s-44.04 17.72-47.59 40.79h-33.51c-3.56-23.07-23.54-40.79-47.59-40.79s-44.04 17.72-47.59 40.79h-33.51c-3.56-23.07-23.54-40.79-47.59-40.79s-44.03 17.72-47.59 40.79H18V71.75h139.17Zm313.56 120.96c0 16.63-13.52 30.15-30.15 30.15s-30.16-13.52-30.16-30.15 13.53-30.15 30.16-30.15 30.15 13.52 30.15 30.15Zm-128.69 0c0 16.63-13.52 30.15-30.15 30.15s-30.16-13.52-30.16-30.15 13.53-30.15 30.16-30.15 30.15 13.52 30.15 30.15Zm-128.69 0c0 16.63-13.53 30.15-30.16 30.15s-30.15-13.52-30.15-30.15 13.52-30.15 30.15-30.15 30.16 13.52 30.16 30.15Zm-58.99 170.21c-16.63 0-30.16-13.53-30.16-30.16s13.53-30.15 30.16-30.15 30.15 13.52 30.15 30.15-13.52 30.16-30.15 30.16Zm286.22 0c-16.63 0-30.16-13.53-30.16-30.16s13.53-30.15 30.16-30.15 30.15 13.52 30.15 30.15-13.52 30.16-30.15 30.16Zm47.59-37.52c-3.56-23.07-23.54-40.79-47.59-40.79s-44.04 17.72-47.59 40.79H201.95c-3.56-23.07-23.54-40.79-47.59-40.79s-44.04 17.72-47.59 40.79H18V203.35h118.24c4.86 21.45 24.06 37.52 46.95 37.52s42.1-16.07 46.96-37.52h34.78c4.86 21.45 24.06 37.52 46.96 37.52s42.1-16.07 46.95-37.52h34.78c4.86 21.45 24.06 37.52 46.96 37.52s42.1-16.07 46.95-37.52h68.9V325.4h-68.26Z',
  ],
}
