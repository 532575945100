export default {
  prefix: 'vv',
  iconName: 'energy',
  icon: [
    383.21,
    545.93,
    [],
    null,
    'm39.65 545.93-15.22-8.48 73.24-281H7.79L0 242.95 137.61 4.5 145.4 0h188.68l7.79 13.5-90.98 157.59H376.7l6.51 15.22L39.65 545.93ZM23.38 238.45h85.94l8.71 11.27L50.6 508.41l305.05-319.32H235.3l-7.79-13.5L318.49 18H150.6L23.38 238.45Z',
  ],
}
