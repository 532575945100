import {library, dom} from '@fortawesome/fontawesome-svg-core'

import {
  faBasketShopping as farBasketShopping,
  faCalendarXmark as farCalendarXmark,
  faCartFlatbedBoxes as farCartFlatbedBoxes,
  faCartShopping as farCartShopping,
  faCheckCircle as farCheckCircle,
  faFerry as farFerry,
  faGlobe as farGlobe,
  faMagnifyingGlass as farMagnifyingGlass,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faTruck as farTruck,
  faTruckContainer as farTruckContainer,
  faUfo as farUfo,
  faXmark as farXmark,
  faCalendarAlt as farCalendarAlt,
  faFloppyDisk as farFloppyDisk,
  faPrint as farPrint,
  faPlus as farPlus,
  faCheck as farCheck,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faInfoCircle as farInfoCircle,
  faFileChartPie as farFileChartPie
} from '@fortawesome/pro-regular-svg-icons'

import {
  faAngleDown as fasAngleDown,
  faAngleRight as fasAngleRight,
  faBadgePercent as fasBadgePercent,
  faBars as fasBars,
  faCartCirclePlus as fasCartCirclePlus,
  faCartShopping as fasCartShopping,
  faCircle as fasCircle,
  faCircleNotch as fasCircleNotch,
  faHouse as fasHouse,
  faLockKeyhole as fasLockKeyhole,
  faMinus as fasMinus,
  faPlus as fasPlus,
  faStore as fasStore,
  faSpinnerThird as fasSpinnerThird,
  faTrashCan as fasTrashCan,
  faXmark as fasXmark,
  faRotateRight as fasRotateRight,
  faClock as fasClock,
  faInfoCircle as fasInfoCircle,
  faText as fasText,
  faCheck as fasCheck,
  faExclamationCircle as fasExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faFacebook as faFacebook,
  faLinkedin as faLinkedin,
  faInstagram as faInstagram,
  faTiktok as faTiktok,
  faYoutube as faYoutube,
  faDocker as faDocker,
} from '@fortawesome/free-brands-svg-icons'

import * as VVIcons from './vilvite-icons'

library.add(
  // Regular
  farBasketShopping,
  farCalendarXmark,
  farCartFlatbedBoxes,
  farCartShopping,
  farCheckCircle,
  farFerry,
  farGlobe,
  farMagnifyingGlass,
  farMagnifyingGlassPlus,
  farTruck,
  farTruckContainer,
  farUfo,
  farCalendarAlt,
  farXmark,
  farFloppyDisk,
  farPrint,
  farPlus,
  farCheck,
  farPhone,
  farEnvelope,
  faDocker,

  // Solid
  fasAngleDown,
  fasAngleRight,
  fasBadgePercent,
  fasBars,
  fasCircle,
  fasCartCirclePlus,
  fasCartShopping,
  fasCircleNotch,
  fasHouse,
  fasLockKeyhole,
  fasXmark,
  fasMinus,
  fasPlus,
  fasTrashCan,
  fasStore,
  fasSpinnerThird,
  fasRotateRight,
  fasClock,
  fasInfoCircle,
  fasText,
  fasCheck,
  fasExclamationCircle,
  farFileChartPie,

  // Brand
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faTiktok,

  farInfoCircle,
  // VilVite icons
  ...Object.values(VVIcons)
)

dom.watch()
